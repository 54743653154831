@mixin bp-sm {
    @media (min-width: 480px) {
        @content;
    }
}

@mixin bp-md {
    @media (min-width: 920px) {
        @content;
    }
}

@mixin bp-lg {
    @media (min-width: 1200px) {
        @content;
    }
}