@import "~@formanta/sass-core/color/function";
@import "mixins";
@import "colors";

* {
    box-sizing: border-box;
}

.App {
    text-align: center;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    $animation_default: 0.4s ease-out background-color, 0.4s ease-out color, 0.4s ease-out fill, 0.4s ease-out border, 0.4s ease-out opacity;

    &,
    .text-light,
    .bg-page,
    .bg-light,
    .bg-dark,
    .border-bottom,
    .border-around,
    .btn,
    button,
    button svg,
    a svg,
    .error > svg,
    svg.error,
    div.error,
    p.error,
    .content a,
    .project-action-history > a,
    .loading-wrapper svg,
    > .header,
    > .center > .content:before,
    > .center > .sidebar,
    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='tel'] {
        transition: $animation_default;
    }

    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='tel'] {
        padding: 6px 6px;
    }

    code {
        padding: 0 4px;
    }

    .btn,
    button {
        padding: 12px 9px;
        text-decoration: none;
        transition: $animation_default, 0.4s ease-out border-radius;

        &:hover {

        }

        &:active {

        }

        &:focus {
            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        &.btn-raw {
            border: 0;
            margin: 0;
            padding: 0;
            cursor: pointer;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .content a,
    a.text {

    }

    a.text {
        text-decoration: none;
    }

    &.light {
        background-color: $light-color--sidebar-bg;

        &,
        .content a,
        a.text {
            color: $light-color--text;
        }

        .text-light {
            color: for-lighten($light-color--text, 40%);
        }

        .bg-page {
            background-color: $light-color--bg;
        }

        .bg-light {
            background-color: for-lighten($light-color--bg, 40%);
        }

        .bg-dark {
            background-color: for-darken($light-color--bg, 10%);
        }

        .bg-row {
            > *:nth-child(1n+1) {
                background-color: $light-color--bg;
            }

            > *:nth-child(2n+1) {
                background-color: for-lighten($light-color--bg, 40%);
            }
        }

        .border-around {
            border: 1px solid for-lighten($light-color--text, 70%);
        }

        .border-bottom {
            border-bottom: 1px solid for-lighten($light-color--text, 70%);

            &:last-child {
                border-bottom: 0;
            }
        }

        button svg {
            fill: $light-color--text;
        }

        &.error > svg,
        svg.error {
            fill: $light-color--error;
        }

        div.error,
        p.error {
            background-color: $light-color--error;
            color: $light-color--bg;
        }

        .loading-wrapper {
            svg {
                fill: $light-color--text;
            }

            &.error svg {
                fill: $light-color--error;
            }
        }

        input[type='text'],
        input[type='email'],
        input[type='password'],
        input[type='tel'] {
            border: 1px solid $light-color--input-border;
            border-top-color: transparent;
            border-right-color: transparent;
            background-color: $light-color--input-bg;
            color: $light-color--input-text;

            &:invalid {
                box-shadow: none;
                border: 1px solid $light-color--input-error-border;
            }
        }

        > .center > .sidebar {
            .btn,
            button {
                color: $light-color--text;
            }
        }

        .btn,
        button {
            border: 1px solid $light-color--btn-border;
            background: $light-color--btn-bg;
            color: $light-color--btn-text;

            &.btn-raw {
                background: none;
                color: $light-color--btn-bg;

                &:hover,
                &:active {
                    color: $light-color--btn-text;

                    svg {
                        fill: $light-color--btn-text;
                    }
                }
            }

            &:hover {
                background: $light-color--btn-hover-bg;
            }

            &:active {
                background: $light-color--btn-active-bg;
            }

            &:focus {
                //border: 1px solid $light-color--btn-focus-border;
            }

            &.btn-raw {
                &.active {
                    background: $light-color--btn-hover-bg;
                    color: $light-color--btn-text;

                    &:active {
                        background: $light-color--btn-active-bg;
                    }

                    svg {
                        fill: $light-color--btn-text;
                    }
                }
            }
        }

        .content a,
        .project-action-history a {
            &:hover {
                background-color: $light-color--btn-hover-bg;
                color: $light-color--btn-text;
            }

            &:active {
                background: $light-color--btn-active-bg;
                color: $light-color--btn-text;
            }
        }

        > .header {
            //background-color: $light-color--bg;
        }

        > .center {
            > .content:before {
                background: $light-color--disabled-content-bg;
            }

            > .sidebar {
                background-color: $light-color--bg;
                border-left-color: for-darken($light-color--sidebar-bg, 20%);
                border-right-color: for-darken($light-color--sidebar-bg, 20%);
                border-bottom: 5px solid for-darken($light-color--sidebar-bg, 20%);
            }
        }
    }

    &.dark {
        background-color: $dark-color--bg;

        &,
        .content a,
        a.text {
            color: $dark-color--text;
        }

        .text-light {
            //color: for-darken($dark-color--text, 60%);
            // todo: change dark-color-text to be able to calc light/dark texts
            color: #778478;
        }

        .bg-page {
            background-color: $dark-color--bg;
        }

        .bg-light {
            background-color: for-lighten($dark-color--bg, 5%);
        }

        .bg-dark {
            background-color: for-darken($dark-color--bg, 20%);
        }

        .bg-row {
            > *:nth-child(1n+1) {
                background-color: $dark-color--bg;
            }

            > *:nth-child(2n+1) {
                background-color: for-lighten($dark-color--bg, 5%);
            }
        }

        .border-around {
            border: 1px solid for-darken($dark-color--sidebar-bg, 0);
        }

        .border-bottom {
            border-bottom: 1px solid for-darken($dark-color--sidebar-bg, 0);

            &:last-child {
                border-bottom: 0;
            }
        }

        button svg {
            fill: $dark-color--text;
        }

        .error > svg,
        svg.error {
            fill: $dark-color--error;
        }

        div.error,
        p.error {
            background-color: $dark-color--error;
        }

        .loading-wrapper {
            svg {
                fill: $dark-color--text;
            }

            &.error svg {
                fill: $dark-color--error;
            }
        }

        input[type='text'],
        input[type='email'],
        input[type='password'],
        input[type='tel'] {
            border: 1px solid $dark-color--input-border;
            border-top-color: transparent;
            border-right-color: transparent;
            background-color: $dark-color--input-bg;
            color: $dark-color--input-text;

            &:invalid {
                box-shadow: none;
                border: 1px solid $dark-color--input-error-border;
            }
        }

        .btn,
        button {
            border: 1px solid $dark-color--btn-border;
            background: $dark-color--btn-bg;
            color: $dark-color--btn-text;

            &.btn-raw {
                background: none;

                &:hover,
                &:active {
                    color: $dark-color--btn-text;
                }
            }

            &:hover {
                background: $dark-color--btn-hover-bg;
            }

            &:active {
                background: $dark-color--btn-active-bg;
            }

            &:focus {
                border: 1px solid $dark-color--btn-focus-border;
            }

            &.btn-raw {
                &.active {
                    background: $dark-color--btn-hover-bg;
                    color: $dark-color--btn-text;

                    &:active {
                        background: $dark-color--btn-active-bg;
                    }

                    svg {
                        fill: $dark-color--btn-text;
                    }
                }
            }
        }

        .content a,
        .project-action-history a {
            &:hover {
                background-color: $dark-color--btn-hover-bg;
                color: $dark-color--btn-text;
            }

            &:active {
                background: $dark-color--btn-active-bg;
                color: $dark-color--btn-text;
            }
        }

        > .header {
            //background-color: $dark-color--bg;
        }

        > .center {
            > .content:before {
                background: $dark-color--disabled-content-bg;
            }

            > .sidebar {
                background-color: $dark-color--sidebar-bg;
                border-left-color: for-darken($dark-color--sidebar-bg, 40%);
                border-right-color: for-darken($dark-color--sidebar-bg, 40%);
                border-bottom: 5px solid for-darken($dark-color--sidebar-bg, 40%);

                @include bp-lg {
                    ///border-right: 3px solid for-darken($dark-color--sidebar-bg, 40%);
                }
            }
        }
    }

    > .header {
        width: 100%;
        display: flex;
        flex-shrink: 0;
        z-index: 2;
        padding: 0 0 0 6px;
        @include bp-lg {
            //padding: 0 0 0 6px;
        }
    }

    > .center {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-grow: 3;
        overflow: auto;
        position: relative;

        > .content {
            display: flex;
            flex-grow: 3;
            overflow: auto;
            position: relative;

            @include bp-lg {
                order: 1;
            }

            &:before {
                content: '';
                opacity: 0;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                @include bp-md {
                    position: static;
                    display: none;
                }
                pointer-events: none;
            }

            .content-container {
                padding: 6px;
            }

            > div {
                > h1,
                > h2,
                > h3,
                > h4,
                > h5,
                > h6 {
                    text-align: left;
                    padding: 0 0 0 6px;
                    @include bp-lg {

                    }
                }
            }

            &.open-sidebar {
                overflow: hidden;
                @include bp-md {
                    overflow: auto;
                }

                .content-container {
                    padding: 6px 6px 6px 6px;

                    @include bp-lg {
                        padding-left: 12px;
                    }
                }

                > div {
                    > h1,
                    > h2,
                    > h3,
                    > h4,
                    > h5,
                    > h6 {
                        text-align: left;
                        @include bp-lg {
                            padding-left: 12px;
                        }
                    }
                }

                &:before {
                    pointer-events: all;
                    opacity: 1;
                }
            }

            h1, h2, h3, h4, h5, h6 {
                font-family: monospace;
            }

            h1 {
                font-size: 1.725rem;
                line-height: 2.4rem;
                @include bp-md {
                    line-height: normal;
                    font-size: 2.125rem;
                }
                margin: 24px 0 24px 0;

                &:first-child {
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-top: 6px;
                    padding-bottom: 6px;
                }

                button {
                    svg {
                        width: 2rem;
                        height: 2rem;
                    }
                }
            }

            h2 {
                font-size: 1.45rem;
                @include bp-md {
                    font-size: 1.85rem;
                }
                margin: 16px 0 16px 0;
            }

            h3 {
                font-size: 1.3rem;
                @include bp-md {
                    font-size: 1.45rem;
                }
                margin: 12px 0 12px 0;
            }

            h4 {
                font-size: 1.10rem;
                @include bp-md {
                    font-size: 1.25rem;
                }
                margin: 9px 0 9px 0;
            }

            h5 {
                font-size: 1.125rem;
                margin: 6px 0 6px 0;
            }

            h6 {
                font-size: 1rem;
                margin: 6px 0 6px 0;
            }
        }

        .sidebar-toggle-left {
            display: none;
            @include bp-lg {
                display: block;
            }
        }

        > .sidebar {
            flex-shrink: 0;
            display: flex;
            overflow: auto;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin-bottom: 5px;
            overflow-x: hidden;
            text-align: right;
            z-index: 2;
            border-left-style: solid;
            border-right-style: solid;
            border-right-width: 0;
            border-left-width: 0;

            @include bp-md {
                position: static;
            }
            @include bp-lg {
                order: 0;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    display: flex;
                    list-style-type: none;
                    flex-direction: column;

                    a,
                    button {
                        width: 100%;
                        display: flex;
                        align-content: center;
                        align-items: center;
                    }

                    button {
                        padding-right: 3px;
                    }
                }
            }

            ul > li > div > ul > li {
                margin-left: 12px;

                button {
                    padding: 6px 3px;
                }
            }
        }

        &.open-sidebar {
            > .sidebar {
                border-left-width: 3px;
                border-right-width: 0;

                @include bp-lg {
                    border-left-width: 0;
                    border-right-width: 3px;
                }
            }
        }
    }
}

.loading-wrapper {
    display: block;
    position: relative;
    overflow: hidden;

    &.center {
        display: flex;
        margin: auto;
    }
}
