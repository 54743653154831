@import "../../mixins";

.project-action-details {
    width: 100%;

    /*h1, h2 {
        text-align: left;
        padding: 0 12px;
        @include bp-md {
            padding: 0 20px;
        }
    }*/
    h1, h2 {
        text-align: left;
    }

    &-container {
        display: flex;
        overflow: auto;
        flex-wrap: wrap;
        @include bp-md {
            flex-grow: 2;
            flex-wrap: nowrap;
        }
    }

    .content-container {
        width: 100%;

        @include bp-md {
            width: auto;
            max-width: 40vw;
        }

        .event-message {
            text-align: left;
        }
    }

    .message-container {
        margin-top: 30px;
        min-height: 50vh;
        scroll-behavior: smooth;

        @include bp-md {
            margin-top: 0;
        }

        .event-message {
            text-align: left;
        }
    }
}

.projects-list {
    width: 100%;

    .projects-list {
        &--project {
            &--envs {
                display: flex;
                flex-wrap: wrap;
            }

            &--env {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 10px auto;
                padding: 12px;
                border-top-width: 0 !important;
                border-left-width: 0 !important;
                border-right-width: 0 !important;
                @include bp-sm {
                    width: 48%;
                    padding: 12px 6px;
                    border-top-width: 1px !important;
                    border-left-width: 1px !important;
                    border-right-width: 1px !important;
                }
                @include bp-md {
                    padding: 12px 20px;
                    margin: 30px auto;
                }
                @include bp-lg {
                    width: 31%;
                    margin: 40px auto;
                }

                h3 {
                    margin-top: 0 !important;
                }

                table {
                    tr {
                        td {
                            padding: 3px;
                            font-family: monospace;
                            text-align: left;
                            word-break: break-word;
                        }
                    }
                }
            }
        }
    }
}