@import "~@formanta/sass-core/color/function";

$light-color--bg: #d8eed4;
$light-color--text: #313845;
$light-color--error: #d61207;
$light-color--btn-bg: $light-color--text;
$light-color--btn-border: transparent;
$light-color--btn-hover-bg: for-darken($light-color--btn-bg, 20%);
$light-color--btn-active-bg: for-darken($light-color--btn-bg, 80%);
$light-color--btn-focus-border: for-lighten($light-color--btn-bg, 40%);
$light-color--btn-text: $light-color--bg;
$light-color--input-bg: #f2f7ef;
$light-color--input-border: for-lighten($light-color--text, 70%);
$light-color--input-error-border: for-lighten(#913b3d, 30%);
$light-color--input-text: $light-color--text;
$light-color--sidebar-bg: #daeeed;
$light-color--disabled-content-bg: rgba(66, 82, 111, 0.5);

$dark-color--bg: #313845;
$dark-color--text: #d8eed4;
$dark-color--text2: #e2eee0;
$dark-color--error: #870e15;
$dark-color--btn-bg: #083137;
$dark-color--btn-border: transparent;
$dark-color--btn-hover-bg: for-darken($dark-color--btn-bg, 20%);
$dark-color--btn-active-bg: for-darken($dark-color--btn-bg, 80%);
$dark-color--btn-focus-border: for-darken($dark-color--btn-bg, 40%);
$dark-color--btn-text: $dark-color--text;
$dark-color--input-bg: #253248;
$dark-color--input-border: for-darken($dark-color--text, 70%);
$dark-color--input-error-border: for-darken(#913b3d, 30%);
$dark-color--input-text: $dark-color--text;
$dark-color--sidebar-bg: #083137;
$dark-color--disabled-content-bg: rgba(for-darken(#083137, 80%), 0.5);